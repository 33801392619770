


import { Component, Vue, Watch, Ref } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { Action, Getter, State } from 'vuex-class';
import ProductList from '@/_modules/promo-hall/components/product-list/product-list.vue';
import DocumentList from '@/views/components/promoPage/documents/documentList.vue';
import CompanyMediaBlock from '@/_modules/promo/components/company-media-block/company-media-block.vue';
import { ContactsFilterType } from '@/_modules/contacts/types/contacts-filter.type';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';
import Tabs from '@/_ui/tabs/tabs.vue';
import { TTab } from '@/_ui/tabs/types/tabs.type';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import { TContact } from '@/_types/contact.type';
import { TEvent } from '@/_types/event.type';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TPromoPageAttachedContacts } from '@/_types/promo-page/promo-page-attached-contacts.type';
import { NavigationGuardNext } from 'vue-router/types/router';
import iconUnFavorite from '@/_modules/icons/components/icon-ew-unfavorite.vue';
import iconFavorite from '@/_modules/icons/components/icon-ew-favorite.vue';
import iconShare from '@/_modules/icons/components/icon-ew-share.vue';
import { Route } from 'vue-router';
import Company from '@/_modules/promo-hall/components/company/company.vue';
import Person from '@/_modules/contacts/components/person/person.vue';
import VideoChatRoomLauncher from '@/_modules/promo-hall/components/video-chat-room-launcher/video-chat-room-launcher.vue';
import EwDocumentList from '@/_modules/promo-hall/components/ew-document-list/ew-document-list.vue';
import CompanyHead from '@/_modules/promo-hall/components/company-head/company-head.vue';
import {TNote} from '@/_types/store/note.type';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';
// TODO: remove all ts-ignores below
// @ts-ignore
import Statistics from '@/services/statistics';
import {TGetNotesParams} from '@/_api/notes/notes.api';

const beforeRouteEnter: { to: Route; from: Route } = { to: null, from: null };

@Component({
  components: {
    DocumentList,
    ProductList,
    CompanyMediaBlock,
    Tabs,
    iconUnFavorite,
    iconFavorite,
    iconShare,
    Company,
    Person,
    VideoChatRoomLauncher,
    EwDocumentList,
    EwButton,
    CompanyHead
  },
  computed: {
    ...mapState({
      attachedContacts: state => (state as TAppStoreState).promoStore.promoPage.attached_contacts,
    }),
  }
})
export default class OpenedCompanyCard extends Vue {

  @Ref('cardReference') cardReference: HTMLElement;

  @Action('contactsStore/openContactCard') openContactCard: (params: { contactId: number }) => void;
  @Action('notesStore/getCompanyPublicNotes') getCompanyPublicNotes: (params: TGetNotesParams) => Promise<any>;

  @Getter('_eventStore/event') readonly event: TEvent;
  @Getter('_eventStore/isStreamingEnabled') isStreamingEnabled: boolean;
  @Getter('promoPageStore/contact') readonly contact: TContact;
  @Getter('promoStore/promoPage') readonly promoPage: TPromoPage;
  @Getter('notesStore/companyNotes') readonly companyNotes: TNote[];

  @State('promoStore/promoPageListLoading') promoPageListLoading: boolean;

  public companyTabList: TTab[] = [
    {
      title: this.$t('title.team'),
      isActive: true,
      rightTopBadge: null,
      index: 0,
    },
    {
      title: this.$t('title.info'),
      isActive: false,
      rightTopBadge: null,
      index: 1,
    },
    {
      title: this.$t('title.products'),
      isActive: false,
      rightTopBadge: null,
      index: 2,
    },
    {
      title: this.$t('title.documents'),
      isActive: false,
      rightTopBadge: null,
      index: 3,
    },
    {
      title: this.$t('title.notes'),
      isActive: false,
      rightTopBadge: null,
      index: 4,
    },
  ];
  public propSlideIndex: number = null;
  public previousTotalHeight: number = 0;
  public previousScrollTop: number = 0;
  public contact_id: number = null;
  public text: string = '';
  public isLoading: boolean = false;
  public isFullScreenModeActive: boolean = false;

  // MAPPED COMPUTED, TODO: use @State or @Getter
  public readonly attachedContacts: TPromoPageAttachedContacts[];

  public get isTeamTabActive(): boolean {
    return !!(this.companyTabList.find(tab => tab.index === 0 && tab.isActive));
  }

  public get isInfoTabActive(): boolean {
    return !!(this.companyTabList.find(tab => tab.index === 1 && tab.isActive));
  }

  public get isProductsTabActive(): boolean {
    return !!(this.companyTabList.find(tab => tab.index === 2 && tab.isActive));
  }

  public get isDocumentsTabActive(): boolean {
    return !!(this.companyTabList.find(tab => tab.index === 3 && tab.isActive));
  }

  public get isNotesTabActive(): boolean {
    return !!(this.companyTabList.find(tab => tab.index === 4 && tab.isActive));
  }

  public get isEventOrganizer(): boolean {
    if (this.event && this.contact && this.contact.user) {
      return this.event.creator_user_id === this.contact.user.id;
    }
    return false;
  }

  public get isAllowedToViewCompanyViewers(): boolean {
    if (this.isEventOrganizer) {
      return true;
    }
    if (!this.promoPage || !this.promoPage.attached_contacts || !this.promoPage.attached_contacts.length || !this.contact) {
      return false;
    }
    return !!this.promoPage.attached_contacts.find(attachedContact => attachedContact.contact.id === this.contact.id);
  }

  public get isReadFullInfoButtonVisible(): boolean {
    return this.promoPage && this.promoPage.description && this.promoPage.description.length >= 645;
  }

  public get teamMembers(): TPromoPageAttachedContacts[] {
    if (!this.promoPage || !this.promoPage.attached_contacts) {
      return [];
    }

    return this.promoPage.attached_contacts.map(member => {
      const data = member;
      data.contact.promopage_external_id = this.promoPage.external_id;
      return data;
    });
  }

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get external_id(): string {
    return this.$route.params.external_id;
  }

  @Watch('external_id', { immediate: true })
  private onExternalIdChange(): void {
    Statistics.promopageView({
      eventId: this.eventId,
      externalId: this.external_id,
    }, beforeRouteEnter);

    this.updateNotesList();
    this.initPromoPage();
    this.initHeightChangeCompensator();
  }

  public beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    beforeRouteEnter.to = to;
    beforeRouteEnter.from = from;
    next();
  }

  public created(): void {
    this.initPromoPage();
  }

  public mounted(): void {
    this.updateNotesList();
  }

  public initHeightChangeCompensator(): void {
    this.$nextTick(() => {
      if (!this.cardReference) {
        return;
      }
      this.cardReference.style.marginBottom = '0';
      this.previousTotalHeight = this.cardReference.getBoundingClientRect().height;
    });
  }

  // public beforeDestroy(): void {
  //   this._stopBroadcastsCheck();
  // }

  public async initPromoPage(): Promise<void> {
    await this.$store.dispatch('promoStore/getPromoPage', {
      external_id: this.external_id,
      event_id: this.eventId
    });
  }

  public updateNotesList(): void {
    if (!this.eventId) {
      return;
    }
    this.getCompanyPublicNotes({
      eventId: this.eventId,
      queryParams: {
        external_id: this.external_id,
      },
    });
  }

  public onActivatedSlideIndex(index: number): void {
    this.propSlideIndex = index;
  }

  public onCompanyCardClosed(eventData: { externalId: string }): void {
    this.$emit('companyCardClosed', eventData); // AW-3130
  }

  public showContact(contact: TContact): void {
    this.openContactCard({ contactId: contact.id });
  }

  public proceedToCompanyViewerContacts(): void {
    try {
      this.$router.push({
        name: 'promo-contacts',
        query: {
          type: ContactsFilterType.BOOTH_VIEWERS,
          boothExternalId: this.$route.params.external_id
        }
      });
    } catch {
      /* ignore */
    }
  }

  public generateLinks(text: string): string {
    return ChatHelper.createLinks(text || '');
  }

  public onContinueToInfoTabClick(): void {
    const infoTabIndex = 1;
    const tab: TTab = this.companyTabList.find(tab => tab.index === infoTabIndex);
    if (tab) {
      this.onActiveTabUpdate(tab, infoTabIndex);
    }
  }

  public onActiveTabUpdate(tab: TTab, newActiveTabIndex: number): void {
    this.previousScrollTop = window.scrollY;
    this.companyTabList.forEach((item, idx) => {
      item.isActive = idx === newActiveTabIndex;
    });
    this.compensateHeightDifference();
  }

  public compensateHeightDifference(): void {
    this.$nextTick(() => {
      if (!this.cardReference) {
        return;
      }
      const newTotalHeight = this.cardReference.getBoundingClientRect().height;
      if (newTotalHeight < this.previousTotalHeight) {
        this.cardReference.style.marginBottom = (this.previousTotalHeight - newTotalHeight).toFixed(2) + 'px';
        window.scrollTo({ top: this.previousScrollTop });
      } else {
        this.previousTotalHeight = newTotalHeight;
      }
    });
  }

  public getAnnouncementDate(announcement: TNote): string {
    return this.$moment(announcement.updated_at).format('DD MMM YYYY HH:mm');
  }

  public getContactPatchedWithCompanyExternalId(contact: TContact): TContact {
    if (contact.promopage_external_id) {
      return contact;
    }

    if (this.promoPage
      && this.promoPage.attached_contacts
      && !!((this.promoPage.attached_contacts || []).find(attachedContact => attachedContact.contact.id === contact.id))
    ) {
      return {
        ...contact,
        promopage_external_id: this.promoPage.external_id,
      };
    }

    return {
      ...contact
    };
  }

  public enableFullscreenMode(): void {
    this.isFullScreenModeActive = true;
    this.$store.dispatch('promoPageStore/setIsMediaBlockOverlayVisible', true);
  }

  public disableFullscreenMode(): void {
    this.isFullScreenModeActive = false;
    this.$store.dispatch('promoPageStore/setIsMediaBlockOverlayVisible', false);
  }

  // public _stopBroadcastsCheck(): void {
  //   this.isBroadcastsCheckEnabled = false;
  // },
  //
  // public async _sleep(milliseconds: number): Promise<void> {
  //   await new Promise(resolve => {
  //     setTimeout(() => {
  //       resolve();
  //     }, milliseconds);
  //   });
  // }
  //
  // public async _startBroadcastsCheck(): Promise<void> {
  //   while (this.isBroadcastsCheckEnabled) {
  //     await this._broadcastsCheck();
  //   }
  // }
  //
  // public async _broadcastsCheck(): Promise<void> {
  //   if (!this.attachedContacts || !this.attachedContacts.length) {
  //     this.broadcasts = [];
  //     await this._sleep(BROADCASTS_CHECK_INTERVAL);
  //     return;
  //   }
  //
  //   const attachedContacts = this.attachedContacts;
  //   const eventId = Number(this.$route.params.eventId);
  //
  //   for (const attachedContact of attachedContacts) {
  //     const { contact } = attachedContact;
  //     const broadcastUrl = MeetingRoomsHelper.getBroadcastUrl({
  //       type: MeetingRoomType.BROADCAST,
  //       eventId: parseInt(this.$route.params.eventId, 10),
  //       contactId: contact.id
  //     });
  //
  //     let checkResult;
  //     try {
  //       checkResult = await axios({method: 'GET', url: broadcastUrl, timeout: 2000});
  //     } catch { /* do nothing */ }
  //
  //     if (!this.isBroadcastsCheckEnabled) {
  //       this.broadcasts = [];
  //       return;
  //     }
  //     if (attachedContacts !== this.attachedContacts || eventId !== Number(this.$route.params.eventId)) {
  //       /* something has changed */
  //       this.broadcasts = [];
  //       await this._sleep(BROADCASTS_CHECK_INTERVAL);
  //       return;
  //     }
  //
  //     const broadcastUrlIndex = this.broadcasts.indexOf(broadcastUrl);
  //     if (checkResult) {
  //       if (broadcastUrlIndex < 0) {
  //         this.broadcasts.push(broadcastUrl);
  //       }
  //     } else {
  //       if (broadcastUrlIndex > -1) {
  //         this.broadcasts.splice(broadcastUrlIndex, 1);
  //       }
  //     }
  //     await this._sleep(BROADCASTS_CHECK_INTERVAL);
  //   }
  // },
}
